@import "../../../../../variables";

.aw-output {

  &.new {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    cursor: pointer;

    .aw-control-grid.inline &.collapsed {
      font-size: 1.75em !important;
    }
  }

  .thumbnail .thumb-warning,
  .thumbnail .thumb-error {
    text-align: center;
    font-size: 14px !important;
  }

  .content-title {
    .badge-label .right .aw-icon {
      font-size: 14px;
      vertical-align: top;
    }
  }

  .aw-control-grid.inline &.aw-control-grid-item.collapsed {
    .header .controls {
      width: 100%;
      button.active {
        background-color: $primary !important;
      }
    }
    .content .main-content .compact {
      >.aw-icon {
        height: 1.5em;
      }
      padding-left: $spacer;
    }
  }

  .actions {
    span ~ button {
      margin-left: 6px;
    }
  }

  .metadata {

    .destination {
      max-width: 90px;
      display: flex !important;
      align-items: center;

      .type {
        flex: 1 1 auto;
      }

      .aw-icon {
        flex: 0 0 auto;
      }
    }
  }

  .main-content .details {
    margin-bottom: .25rem;

    .audio-levels {
      margin-bottom: .75rem;
    }
  }
}